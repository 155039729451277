<template>
    <div>
        <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
            <!-- Card stats -->
        </base-header>

        <b-container fluid class="mt--7">
            <b-row>
                <b-col>
                    <b-overlay :show="loading">
                        <b-card no-body>
                            <b-card-header class="border-1">
                                <h3 class="mb-0">勤怠一覧</h3>
                            </b-card-header>
                            <b-card-body>
                                <!-- <div>
                                    <b-form-group class="text-right">
                                        <b-input-group size="sm">
                                            <b-form-input type="search" v-model="search" placeholder="勤怠タイトルで検索"></b-form-input>
                                            <b-input-group-append>
                                                <b-button :disabled="!search" @click="search = null">クリア</b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form-group>
                                </div> -->
                                <b-table
                                        :items="attendances"
                                        :fields="fields"
                                        :current-page="currentPage"
                                        :per-page="perPage"
                                        stacked="md"
                                        :filter="search"
                                        :filter-included-fields="['date']"
                                        @filtered="onFiltered"
                                        show-empty
                                        small
                                        hover
                                >
                                <template #cell(actions)="row">
                                    <b-button
                                            size="sm"
                                            variant="link"
                                            @click="getPdf(row.item.id)"
                                    >
                                        <b-icon icon="file-earmark-arrow-down" variant="primary"></b-icon>
                                    </b-button>
                                </template>
                                </b-table>
                            </b-card-body>
                            <b-card-footer>
                                <b-row>
                                    <b-col cols="12" class="d-flex justify-content-between">
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="totalRows"
                                                :per-page="perPage"
                                                size="md"
                                        ></b-pagination>
                                        <span>
                                        <b-button variant="dark" :to="{name: 'attendance-pdf'}">戻る</b-button>
                                        </span>
                                    </b-col>
                                </b-row>

                            </b-card-footer>
                        </b-card>
                    </b-overlay>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
    import { ajax, common, config } from '../../utils';
    import { helper } from '../common';

    export default {
        data() {
            return {
                loading: true,
                attendances: [],
                fields: [
                    //{ key: 'id', sortable: true, label: '勤怠ID' },
                    { key: 'date', sortable: true, label: '勤怠月分' },
                    { key: 'actions', label: 'Actions', class: 'text-center' },
                ],
                currentPage: 1,
                perPage: config.rowsPerPage,
                search: null,
                totalRowsFilted: null,
            }
        },
        computed: {
            totalRows: function() {
                return this.search ? this.totalRowsFilted : this.attendances.length;
            },
        },
        created: function () {
            document.title = common.getPageName(this.$route.name);
            this.getAttendanceList();
        },
        methods: {
            getAttendanceList: function() {
                const vm = this;
                ajax.fetchGet(common.formatStr(config.api.employee.attendance_list, this.$route.params.id)).then(data => {
                    vm.attendances = data;
                }).catch(errors => {
                    helper.show_errors(errors);
                }).finally(() => {
                    vm.loading = false;
                });
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRowsFilted = filteredItems.length;
            },
            getPdf(attendance_id) {
                const vm = this;
                ajax.fetchGet(common.formatStr(config.api.employee.attendance_download, this.$route.params.id, attendance_id)).then(data => {
                    const blob = vm.dataURItoBlob(data);
                    const url = URL.createObjectURL(blob);

                    window.open(url, '_blank');
                }).catch(errors => {
                    helper.show_errors(errors);
                });
            },
            dataURItoBlob(dataURI) {
                const byteString = window.atob(dataURI);
                const arrayBuffer = new ArrayBuffer(byteString.length);
                const int8Array = new Uint8Array(arrayBuffer);
                for (let i = 0; i < byteString.length; i++) {
                    int8Array[i] = byteString.charCodeAt(i);
                }
                const blob = new Blob([int8Array], { type: 'application/pdf'});
                return blob;
            },
        }
    }
</script>

<style scoped>

</style>